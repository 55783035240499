.chat-box {
  display: flex;
  flex-direction: column;
  flex: 3;
  
}

.player-main {
//   width: 100%;
  color: #D4F2D2;
  float: right;
}

.playerview-wrapper {
  width: 20%;
  background-color: #63768D;
  display: flex;
  justify-content: center;
  align-items: center;
  float: right;
  
}

.playerview {
  width: 27%;
  background-color: #32292F;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0E1428;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
  float: right;
  position: fixed;
  right: 5px;
  margin-top: 340px;
}

.playerview-inner {
  width: 90%;
  height: 75%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow-y: scroll;
  margin-bottom: 50px;
}

.player-item {
  width: 100%;
  display: block;
  padding: 10px;
}

.player-message-info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  width: 50%;
  padding-bottom: 4px;
}

.player-date {
  font-size: 12px;
  font-weight: 700;
  color: #575366;
}

.player-username {
  margin-right: 2rem;
  font-size: 16px;
  font-weight: 700;
  color: #A2ACC9;
}

.player-body {
  font-size: 15px;
}

.playertext-editor-wrapper {
  width: 20%;
  height: 20%;
  border-top: #0E1428 4px solid;
  box-sizing: border-box;
  background-color: #575366;
  display: flex;
  align-items: center;
  overflow-y: scroll;
  float: right;
}

.playertext-editor-form {
  width: 100%;
  display: flex;
  height: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.playertext-input-area {
  background-color: #32292F;
  border: 1px solid #0E1428;
  display: flex;
  justify-self: center;
  align-items: center;
  border-radius: 3px;
  padding: 15px;
  width: 26%;
  position: fixed;
  right: 11px;
  margin-top: 575px;
  background: radial-gradient(#565366, transparent);
}