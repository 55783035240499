.editor {
  background-color: #f5f2f0;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 200px;
  // height: 400px;
  width: 100%;
  margin: 20px;
}

.editor-info {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.editorPre {
  min-width: 500px;
  width: 100%;
  font-size: 13px;
  // background-color: #E4E6C3;
}

.editor-counts {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding-left: 10px;
}

.editor-count {
  padding-right: 10px;
}

.code-submit {
  background-color: #D4F2D2;
  color: #32292F;
  width: 300px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  border-radius: 3px;
  margin-top: 25px;
  border: 2px solid #32292F;
  text-transform: uppercase;
  font-family: 'VT323', monospace;
  font-size: 28px;
  &:hover {
    color: #D4F2D2;
    background-color: #32292F;
    border: 2px solid #D4F2D2;
  }
}

.pending-submit {
  cursor: wait;
}

.judgement {
  font-family: 'VT323', monospace;
  font-size: 28px;
  font-weight: 500;
}