.tour-wrapper {
  font-family: 'Roboto', sans-serif;
  color: #D4F2D2;
}

.lobby-sidebar {
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 15rem;
  height: 10rem;
  left: 10rem;
  top: 3.6rem;
}

.lobby-button {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  position: fixed;
  width: 15rem;
  height: 10rem;
  right: 2rem;
  top: 5.5rem;
}

.lobby-chat {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: fixed;
  width: 15rem;
  height: 10rem;
  left: 20rem;
  bottom: 8rem;
}

.game-challenge {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 18rem;
  height: 10rem;
  left: 35%;
  top: 7rem;
}

.game-editor {
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 18rem;
  height: 10rem;
  left: 20rem;
  top: 7rem;
}

.game-submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  width: 18rem;
  height: 10rem;
  left: 35%;
  top: 40%;
}

.game-stats {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  position: fixed;
  width: 18rem;
  height: 12rem;
  left: 16%;
  top: 39%;
}

.tour-text-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #0E1428;
  width: 100%;
  height: 100%;
  text-align: center;
  border:  2px solid #D4F2D2;
  border-radius: 4px;
}

.tour-text {
  margin: 0 1rem;
  font-size: 1.2rem;
}

.tour-arrow-left {
  font-size: 1.5rem;
  color: #0E1428;
  animation: left-right 1s ease-in-out infinite;
  margin-right: 9px;
  margin-top: 8px;
}

.tour-arrow-right {
  font-size: 1.5rem;
  color: #0E1428;
  animation: left-right 1s ease-in-out infinite;
  margin-left: 18px;
}

.tour-arrow-left-right {
  font-size: 3rem;
  color: #0E1428;
  animation: left-right 1s ease-in-out infinite;
  margin-left: 18px;
}

.tour-arrow-up {
  font-size: 1.5rem;
  color: #0E1428;
  animation: up-down 1s ease-in-out infinite;
  margin-left: 13.25rem;
}

.tour-arrow-down {
  position: absolute;
  font-size: 1.5rem;
  color: #0E1428;
  animation: up-down 1s ease-in-out infinite;
  bottom: -52px;
  left: 0px;
}

.code-duel {
  font-family: 'VT323', monospace;
  font-size: 1.4rem;
  font-weight: 700;
}

.tour-button {
  margin: 1rem 1rem .5rem .5rem;
  padding: .2rem .4rem;
  border-radius: 2px;
  color: #6E7DAB;
  background-color: #0E1428;
  border: 1px solid #6E7DAB;
  &:hover {
    background-color: #6E7DAB;
    color: #D4F2D2;
    border: 1px solid #D4F2D2;
  }
}

@keyframes left-right {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(-15px, 0);
  }
}

@keyframes up-down {
  0%,
  100% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, -15px);
  }
}