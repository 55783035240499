.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  background: rgba(110, 125, 171, 0.6);
}

.modal {
  width: 600px;
  max-width: 100%;
  height: 400px;
  max-height: 100%;
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #575366;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  color: #D4F2D2;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;;
  font-family: 'Roboto', sans-serif;
}

.modal-info {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  height: 150px;
  width: 80%;
  h1 {
    font-size: 50px;
    font-weight: 700;
    margin: 10px 0 0 0;
    font-family: 'VT323', monospace;
    text-transform: uppercase;
  }
  div {
    display: inline-block;
    font-size: 18px;
    font-weight: 700;
    p {
      display: block;
      font-weight: 700;
      font-size: 36px;
      font-family: 'VT323', monospace;
    }
  }
}

.modal-buttons {
  z-index: 200;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
}

.modal-decline {
  z-index: 200;
  background-color: #32292F;
  padding: 10px 15px;
  width: 40%;
  border: 1px solid #0E1428;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  border-radius: 3px;
}

.modal-accept {
  z-index: 200;
  background-color: #D4F2D2;
  color: #32292F;
  width: 40%;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  border-radius: 3px;
}

.center {
  text-align: center;
}

.decline-hover {
  &:hover {
    color: #32292F;
    background-color: #D4F2D2;
  }
}

// =========Stats Modal==========

.stats-modal {
  height: fit-content;
  width: fit-content;
  max-width: 80%;
  max-height: 80%;
  overflow: auto;
  position: fixed;
  z-index: -100;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #E4E6C3;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #D4F2D2;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;;
  font-family: 'Roboto', sans-serif;
}

.error-block {
  display: flex;
  z-index: 50;

}

.error-hidden {
  display: none;
}

.results-block {
  display: flex;
  z-index: 50;
}

.results-hidden {
  display: none;
}

.stats-modal-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  overflow-wrap: break-word;
  color: maroon;
  width: fit-content;
  height: fit-content;
  max-width: 100%;
}

.stats-modal-error {
  align-items: flex-start;

  >pre {
    margin: 0;

    &:last-of-type {
      margin-bottom: 2rem;
    }
  }
}

.stats-modal-buttons {
  z-index: 200;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
}

.stats-modal-results {
  font-size: 22px;
  max-width: 100%;
}