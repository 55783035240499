.question-text-box {
  color: #D4F2D2;
  text-align: center;
  background-color: #575366;
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  font-size: 1.125rem;
  height: 100%;
  padding: 0.5rem;
  overflow-y: auto;
  
  h1 {
    font-weight: 600;
    border-bottom: 1px solid;
  }

  * {
    margin: 0.35rem 0;
  }
}

.stats-players {
  display: flex;
  justify-content: center;
  align-items: center;
}

.stats-form{
  min-width: 150px;
  width: 90%;
  margin: 0 5px;
  background-color: #32292F;
  justify-content: center;
  align-items: center;
  border: 1px solid #0E1428;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  h1{
    background-color:#d1eed0;
    text-align: center;
    padding: 7px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
  
  }
  h2{
    color: #d4f2d2;
    
  }

  .stats{
    margin: 1rem 0;
    text-align: left;
    padding: 5px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
}

.stats > h2 {
  margin: 0.3rem 0;
}

.game-screen {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.game-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}

.game-right {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-direction: column;
  min-width: 500px;
  box-sizing: border-box;
  flex: 1;
  padding: 20px;
  background-color: #63768D;
}

.game-chat-wrapper {
  display: flex;
  width: 100%;
  // height: 20rem;
  flex: 1;
}

#game-chat {
  border-left: #0E1428 4px solid;
}

.code-editor-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  flex-direction: column;
  width: 95%;
  max-width: 800px;
  margin-bottom: 20px;
}

.challenge-question-wrapper {
  flex-basis: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.stats-results {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;
  h2 {
    width: 60%;
    display: flex;
    justify-content: center;
    align-self: center;
    font-size: 16px;
    background-color: #D4F2D2;
    color: #0E1428;
    border-radius: 4px;
    padding: 3px 6px;
    // margin: 0 1rem 8px;
    margin-bottom: 8px;
    &:hover {
      cursor: pointer;
    }
  }
}

.stats-wrapper {
  display: flex;
  align-items: center;
  width: 80%;
  justify-content: space-around;
  height: 100%;
  flex: 1;
}

.stats-wrapper > div {
  flex: 1;
}

.stats-title {
  font-family: 'VT323', monospace;
  font-size: 22px;
}