.spectator-wrapper {
  display: flex;
  flex-direction: column;
  // width: inherit;
  // height: inherit;
}

.screen-top {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 35px;
}

.screen-bottom {
  display: flex;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  margin-bottom: 20px;
}

.spectator-player1 {
  display: flex;
  height: 100%;
}

.spectator-player2 {
  display: flex;
  height: 100%;
}

.player-components {
  display: flex;
}

.player-stats {
  display: flex;
  align-self: center;
  padding-top: 20px;
}

.stats-wrapper-spectator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex: 1;
}

.code-editor-wrapper-spectator {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

.challenge-question-wrapper-spectator {
  padding: 10px 26px 0;
  flex: 1;
}

.game-chat-wrapper-spectator {
  display: flex;
  flex: 1;
  padding-right: 20px;
}

.main-spectator {
  background-color: #63768D;
  font-family: 'Roboto', sans-serif;
  display: flex;
}