.splash {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  flex-direction: column;
  font-family: 'VT323', monospace;
}

.slideshow {
  display: flex;
  flex: 3;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  min-width: 800px;
}

.splash-arrow {
  font-size: 2rem;
  color: #D4F2D2;
  padding: 1rem;
  border-radius: 3px;
  &:hover {
    color: #0E1428;
  }
}

.splash-button {
  color: #D4F2D2;
  padding: 0.5rem 1rem;
  margin: 2rem;
  background-color: #6E7DAB;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
  &:hover {
    background-color: #D4F2D2;
    color: #6E7DAB;
  }
}

.splash-controls-bottom {
  display: flex;
  flex: 1;
  width: inherit;
  align-items: center;
  justify-content: space-evenly;
}

.card {
  position: relative;
  
  width: 1050px;
  height: 600px;
  overflow: hidden;

  border-radius: 5px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);

  z-index: 0;
}

/*Light blue cover above the slide show*/
.card::after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  z-index: 900;

  display: block;
  width: 100%;
  height: 100%;

  background-color: rgba(140, 22, 115, 0.2);
}

.card_part {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  flex-direction: column;
  text-align: center;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: #575366;


  width: 100%;
  height: 100%;
  
  transform: translateX(1050px);

  background-image: url( https://codedueler.s3.us-east-2.amazonaws.com/welcome.gif );
  background-position: center;
  background-size: cover;
  transition: cubic-bezier(0, 0, 0.2, 1) 1.3s;

  >div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
} 

.card_part.card_part-2 {
  z-index: 4;
  background-image: none;
}

.card_part.card_part-3 {
  z-index: 5;
  background-image: none;
  
  >p {
    display: inline;

    >div {
      display: inline;
    }
  }

  >div {
    max-width: 100%;
    >img{
      width: 50%;
    }
  }
}

.card_part.card_part-4 {
  z-index: 4;
  background-image: none;

  >div {
    justify-content: center;
    >img{
      width: 75%;
    }
  }
}

.card_part.card_part-5 {
  z-index: 6;
  background-image: none;
  
  >div {
    justify-content: center;
    >img{
      width: 75%;
    }
  }
}

.card_part.card_part-6 {
  z-index: 6;
  background-image: none;

  >div {
    justify-content: center;
    >img{
      width: 75%;
    }
  }
}

.card_part.card_part-7 {
  z-index: 6;
  background-image: url( https://codedueler.s3.us-east-2.amazonaws.com/smacktalk.gif );
}

.card_part.card_part-8 {
  z-index: 6;
  background-image: none;
}

.card-out {
  transform: translateX(-1050px);
}

.card-in {
  transform: translateX(0);
}

.button-none {
  visibility: hidden;
}

.slide-text {
  display: flex;
  margin: 2rem;
  font-size: 2.5rem;
  color: #D4F2D2;
  flex: 1;
  align-items: flex-end;
}

.flex-start {
  align-items: flex-start;
};

// @keyframes opaqTransition {
//   3% { transform: translateX( 0 ); }
//   25% { transform: translateX( 0 ); }
//   28% { transform: translateX( -700px ); }
//   100% { transform: translateX( -700px ); }
// }

