.nav-bar-wrapper {
  min-height: 56px;
  background-color: #0E1428;
  color: #D4F2D2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'VT323', monospace;
  font-size: 36px;
  box-sizing: border-box;
  z-index: 400;
  position: fixed;
  width: 100vw;
  padding: 0 1rem;
  overflow: visible;
}

.nav-bar-spacer {
  min-height: 56px;
}

.logo-wrapper {
  // position: relative;
  width: fit-content;
  position: absolute;
  top: 2px;
  left: calc(50vw - 96px);
}

.logo-nav {
  display: flex;
  justify-self: center;
  align-items: center;
  font-size: 48px;
  padding: 0rem 0rem;
  &:hover {
    cursor: pointer;
  }
}

.left-nav {
  display: flex;
  justify-self: center;
  align-items: center;
  padding: .25rem .250rem;
  font-size: 26px;
}

.other-left {
  display: none;
}

@media only screen and (max-width: 680px) {
  .left-nav {
    display: none;
  }

  .other-left {
    display: block;
  }
  
  .logo-wrapper {
    left: unset;
    right: 12px;
  }
}

.nav-buttons {
  content: unset;
  display: flex;

  >div {
    display: flex;
    align-items: center;
    justify-content: center;
    white-space: nowrap;
  }
}

.nav-button {
  cursor: pointer;
  justify-self: center;
  align-items: center;
  margin: 0 0.5rem;
  padding: .25rem .750rem;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 700;
  background-color: #6E7DAB;
  border: solid #575366 1px;
  border-radius: 3px;
  width: fit-content;
  display: flex;
  justify-content: center;
  &:hover {
    background-color: #575366;
  }
}

.nav-burger {
  position: relative;
  cursor: pointer;
}

.burger-content {
  cursor: default;
  position: absolute;
  right: 0;
  background-color: #32292F;
  border: 1px solid #E4E6C3;
  box-shadow: 0 0 3px #63768D;
  height: fit-content;
  padding: 0.5rem 0;
  width: 150px;
  display: flex;
  flex-direction: column;

  >div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
}

.burger-content > * > * {
  background-color: #32292F;
  border: unset;
}


@media only screen and (max-width: 610px) {
  .burger-content {
    right: unset;
    left: 0;
  }
}

.inactive {
  cursor: default;
  background-color: #63656a;
  &:hover {
    background-color: #63656a;
  }
}

.nav-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
}