.chat-box {
  display: flex;
  flex-direction: column;
  flex: 3;
}

.chat-main {
  width: 100%;
  color: #D4F2D2;
  max-height: inherit;
}

.chatview-wrapper {
  width: 100%;
  height: calc(100% - 80px);
  background-color: #63768D;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatview {
  width: 92%;
  background-color: #32292F;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0E1428;
  border-radius: 5px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
}

.chatview-inner {
  width: 92%;
  height: 90%;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
}

.chat-item {
  display: block;
  padding: 10px;
  width: 90%;
}

.chat-message-info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  padding-bottom: 4px;
}

.chat-date {
  font-size: 12px;
  font-weight: 700;
  color: #575366;
}

.chat-username {
  margin-right: 2rem;
  font-size: 16px;
  font-weight: 700;
  color: #A2ACC9;
}

.chat-body {
  font-size: 15px;
  overflow-wrap: anywhere;
}

.text-editor-wrapper {
  width: 100%;
  height: 80px;
  min-height: 80px;
  border: #0E1428 4px solid;
  box-sizing: border-box;
  background-color: #575366;
  display: flex;
  align-items: center;
}

.text-editor-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
}

.text-input-area {
  background-color: #32292F;
  border: 1px solid #0E1428;
  display: flex;
  justify-self: center;
  align-items: center;
  border-radius: 3px;
  padding: 10px;
  width: 96%;
}