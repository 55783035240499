html,body {
  height: 100%;
}

#root {
  height: 100%;
  width: 100%;
  min-height: fit-content;
  min-width: fit-content;
}

.scroll-bar {
  --scrollbarBG: #0E1428;
  --thumbBG: #D4F2D2;
}
.scroll-bar::-webkit-scrollbar {
  width: 11px;
}
.scroll-bar {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
}
.scroll-bar::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
.scroll-bar::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG) ;
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.main {
  background-color: #63768D;
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex: 1;
  max-height: calc(100vh - 56px);
  min-height: fit-content;
}

.global {
  display: flex;
  flex-direction: column;
  height: inherit;
  width: inherit;
}

.vertical-half {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 16px;
}

.vertical-half > div {
  height: 50%;
}

.vertical-half > div:first-of-type {
  flex: 1;
  max-height: fit-content;
}

.vertical-half > div:last-of-type {
  flex: 2;
}

.hidden {
  display: none !important;
}

.visible {
  display: block;
}

.pointer {
  cursor: pointer !important;
}

.cursor-default {
  cursor: default;
}
