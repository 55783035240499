.session-form {
  width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background-color: #575366;
  color: #D4F2D2;
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,.2);
}

.signup-page {
  display: flex; 
  justify-content: center;
  align-items: center;
  width: 100%;
}

.session-input {
  background-color: #32292F;
  padding: 10px 15px;
  width: 400px;
  border: 1px solid #0E1428;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  border-radius: 3px;
}

.session-header {
  font-size: 24px;
  font-weight: 700;
  margin: 10px 0 0 0;
}

.input-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.session-label {
  margin-bottom: 10px;
  font-size: 14px;
  font-weight: 700;
}

.session-button {
  background-color: #D4F2D2;
  color: #32292F;
  width: 400px;
  padding: 10px 15px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  border-radius: 3px;
  margin: 0px 0 30px 0;
}

.session-button:first-of-type {
  margin-top: 20px;
}

.session-errors {
  color: red;
  text-transform: capitalize;
  font-style: italic;
  font-weight: 700;
}

.session-sub-header {
  font-size: 16px;
  margin: 10px 0 20px 0;
}